.yellow-theme {
  --primary: #f1b60d;
  --secondary: #f2cb05;
  --tertiary: #fbf8d7;
  --color-icon: #f2cb05;
  --dark: #e1ab0e;
  --menu: #f1b60d;

  // path da imagem correspondente
  --dashImg: url("../../assets/images/research-tensai-yellow.svg");
}
