/*CSS para impressão*/
@media print {
  * {
    background:transparent !important;
    color:#000 !important;
    text-shadow:none !important;
    filter:none !important;
    -ms-filter:none !important;
  }
    
  body {
    margin:0;
    padding:0;
    line-height: 1.4em;
    text-align: center;
  }
  
  nav, footer, video, audio, object, embed, button, input, [class^="icon"], [classˆ="fa"] { 
    display:none; 
  }

  @page {
    margin: 0.5cm;
  }

  .print {
    display:block;
  }
    
  .no-print { 
    display:none!important; 
  }

  .dashPage {
    margin-left: 0px;
    margin: 1cm !important;
  }

  .sg-table {
    border: 1px solid #000;

    thead th {
      border-bottom: 1px solid #000!important;
      position: relative;
    }

    tbody tr {
      border: 1px solid #000;

      td {
        padding: .3cm 0;
      }
    }
  }
  
  .header-component {
    justify-content: center!important;
  }

  .dashHeader {
    justify-content: center!important;
  }
}
