.orange-theme {
  --primary: #f27405;
  --secondary: #f29f05;
  --tertiary: #fdf0d8;
  --color-icon: #f29f05;
  --dark: #d86500;
  --menu: #f27405;

  // path da imagem correspondente
  --dashImg: url("../../assets/images/research-tensai-orange.svg");
}
