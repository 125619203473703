.neutros {
  --white: #ffffff;
  --gray100: #F8FAFB;
  --gray150: #EBEAEA;
  --gray200: #DFDFDF;
  --gray300: #6B7986;
  --gray400: #495F6E;
  --gray500: #202428;
}

.alertas {
  --success100: #f2fcfc;
  --success200: #007a80;
  --error100: #ffd2da;
  --error200: #b31733;
  --tip100: #f4edf6;
  --tip200: #610082;
  --alert100: #ffeccf;
  --alert200: #ca8b41;
  --info100: #EEF9FF;
}

.status {
  --status-error: #eb5757;
  --status-sucess: #04bd00;
  --status-done: #04bd00; // remover após mapeamento do novo tema
  --status-in-progress: #57b6eb;
  --status-warning: #F5D665;
}

.medals {
  --gold-100: #FCF0CF;
  --gold-200: #F1B10D;
  --gold-opacity-20: #F1B60D33;
  --silver-100: #E7E7E7;
  --silver-200: #878787;
  --silver-opacity-20: #87878733;
  --bronze-100: #FBE9DE;
  --bronze-200: #ED905C;
  --bronze-opacity-20: #ED905C33;
}
