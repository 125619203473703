.red-theme {
  --primary: #d91e2e;
  --secondary: #f22e3e;
  --tertiary: #ffe6e8;
  --color-icon: #f22e3e;
  --dark: #c31c2b;
  --menu: #d91e2e;

  // path da imagem correspondente
  --dashImg: url("../../assets/images/research-tensai-red.svg");
}
