.default-theme {
  --primary: #3251ea;
  --secondary: #2ca0f4;
  --tertiary: #eaf6fe;
  --color-icon: #2ca0f4;
  --dark: #2841bb;
  --menu: #12004a;

  // path da imagem correspondente
  --dashImg: url("../../assets/images/research-tensai-default.svg");
}
