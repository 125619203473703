@import "./assets/css/variables";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-icons/iconfont/material-icons.css";
// regular style toast
@import "~ngx-toastr/toastr.css";
@import "./styles/theme";

.ngx-toastr {
  background-color: #e74c3c;
}

#step-header-listener {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba($color: #fff, $alpha: 0);
  border: none;
}

// CSS RESET - elimina os estilos default dos navegadores sobre as tags html

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

img,
object,
embed,
video,
iframe {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Open Sans", sans-serif;
}

button,
textarea,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="time"] {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
strong,
em,
i {
  word-break: break-word;
}

/* Largura da barra de rolagem */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

// ::-webkit-scrollbar {
//   width:6px;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #00BEC1;
//   border-radius: 30px
// }

// ::-webkit-scrollbar-track {
//   background: transparent;
//   border: 1px solid transparent;
//   background-clip: content-box;
// }

// FIM RESET
// Default - Estilos que se repetem na maioria ou em todas as telas
.container-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-component {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  padding: 2px 3px;

  .breadcrumb {
    display: flex;
    align-items: baseline;
    flex-basis: 390px;

    i {
      margin-right: 10px;
      font-size: 20px;
      color: #777;
    }

    h2 {
      color: #777;
      font-size: 26px;
      font-weight: 200;

      &:hover {
        color: #00bec1;
        cursor: pointer;
      }
    }

    h2.active {
      color: #777;
      cursor: auto;
    }

    span {
      margin: 0 10px;
      font-size: 18px;
      color: #8b8b8c;
    }

    h4 {
      font-size: 16px;
      color: #8b8b8c;
      font-weight: 200;
    }
  }

  @media screen and (max-width: 655px) {
    justify-content: center;

    .breadcrumb {
      margin-bottom: 20px;
    }
  }
}

.pageContent {
  width: 100%;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 6px;
  padding: 30px;
}

// Tabela: estilo de tabela default
// Uso: adicionar a classe "sg-table" no <table>
table.sg-table {
  width: 100%;
  // background-color: #fff;
  border-radius: 6px;
  border-collapse: collapse;

  thead {
    border-bottom: 1px solid #eee;

    th {
      text-align: center;
      padding: 12px 20px;
      color: #444;
      font-weight: 400;
      position: sticky;
      top: 52px;
      background-color: #fff;
      border-bottom: 1px solid #eee;
      z-index: 9;

      input {
        cursor: pointer;
      }
    }
  }

  .sg-check {
    cursor: pointer;
  }

  tbody tr {
    background-color: #fff;

    td {
      color: #8b8b8c;

      @media screen and (min-width: 961px) {
        text-align: center;
        padding: 15px 0px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  table {
    border: 0;

    thead {
      display: none;
    }

    tbody.isChild tr {
      background-color: rgba(98, 39, 130, 0.05);
      margin-left: 20px;
      margin-right: 20px;
    }

    tr {
      display: block;
      margin-bottom: 30px;
      border-radius: 5px;
    }

    td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 14px;
      text-align: right;
      padding: 15px 20px;
      text-align: right;

      &::before {
        content: attr(title);
        float: left;
        font-weight: 600;
        color: #622782;
        text-transform: uppercase;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

data-label {
  font-family: inherit;
}

// FIM TABELA
// Botão
button.sg-btn {
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 30px;
  font-size: 14px;
  border: none;
  height: 44px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
}

button.sg-btn-small {
  cursor: pointer;
  padding: 0px 30px;
  border-radius: 30px;
  font-size: 14px;
  border: none;
  height: 30px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
}

button.sg-secondary {
  background-color: #00bec1;
  color: #fff;

  &:hover {
    opacity: 0.7;
  }
}

button.sg-transparent {
  background-color: transparent;
  color: #8b8b8c;

  &:hover {
    opacity: 0.7;
  }
}

button.sg-alternate {
  background-color: transparent;
  color: #00bec1;
  border: 1px solid #00bec1;

  &:hover {
    opacity: 0.7;
  }
}

button.sg-primary {
  background-color: #632782;
  color: #fff;
  font-weight: 400;

  &:hover {
    opacity: 0.7;
  }
}

button.sg-warning {
  background-color: #e74c3c;
  color: #fff;
  font-weight: 400;

  &:hover {
    opacity: 0.7;
  }
}

button.sg-secondary:disabled {
  cursor: not-allowed;
  background-color: #ccc;

  &:hover {
    opacity: 1;
  }
}

// Formulários e Inputs
.sg-input-group input[type="text"],
.sg-input-group input[type="number"],
.sg-input-group input[type="email"],
.sg-input-group input[type="password"],
.sg-input-group input[type="date"],
.sg-input-group input[type="time"] {
  padding: 13px 20px;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  border: 1px solid #c9cfdc;
  color: #777777;
  font-size: 13px;
  width: 100%;
  height: 43px;

  &::placeholder {
    color: rgb(201, 207, 202);
  }
}

.sg-input-group input[type="date"] {
  min-width: 175px;
}

input[type="checkbox"] {
  cursor: pointer;
}

.sg-input-group input[type="text"]:focus,
.sg-input-group input[type="number"]:focus,
.sg-input-group input[type="email"]:focus,
.sg-input-group input[type="password"]:focus,
.sg-input-group input[type="date"]:focus,
.sg-input-group input[type="time"]:focus,
.sg-input-group select:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
}

.sg-input-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 30px 10px 20px;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  border: 1px solid #c9cfdc;
  color: #777;
  font-size: 13px;
  width: 100%;
  height: 43px;
  background-image: url("/assets/images/down-arrow.png");
  background-size: 17px;
  background-position: 98%;
  background-repeat: no-repeat;

  option {
    padding: 0 30px 0 0;
  }
}

.sg-input-group input.auto-complete {
  background-image: url("/assets/images/search.png");
  background-size: 17px;
  background-position: 97%;
  background-repeat: no-repeat;
}

.sg-input-group .campo {
  margin-bottom: 25px;
}

.sg-input-group .campo-inline {
  margin-bottom: 25px;
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.sg-input-group .campo-inline > label {
  padding: 0px 20px;
  color: #919191;
  font-size: 17px;
  font-weight: 400;
  flex-grow: 1;
  // width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sg-input-group .campo-inline div > label {
  margin-left: 20px;
}

.sg-input-group .campo label {
  display: block;
  padding-left: 0px;
  margin-bottom: 9px;
  color: #919191;
  font-size: 17px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

input:disabled,
select:disabled {
  background-color: $color-light-gray;
  cursor: not-allowed;
}

.sg-input-group .campo textarea {
  padding: 13px 20px;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  border: 1px solid #c9cfdc;
  color: #777777;
  font-size: 13px;
  width: 100%;
  height: 200px;
  resize: none;
  font-family: inherit;

  &::placeholder {
    color: rgb(201, 207, 202);
  }

  &:focus {
    border-color: #00bec1;
  }
}

.sg-checkbox {
  width: 200px;
}

.sg-checkbox-full {
  width: 100%;
}

// Checkbox Customizado
.sg-checkbox,
.sg-checkbox-full {
  min-height: 40px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 15px;

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }

  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {
    position: relative;
    color: #610082;
    background-color: rgba(85, 208, 212, 0.2);
    border: 1px solid #00bec1;
  }

  input[type="radio"]:checked + label::after,
  input[type="checkbox"]:checked + label::after {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    background-image: url("/assets/images/verified.svg");
    right: 10px;
    position: absolute;
    border-radius: 50%;
    background-size: contain;
  }

  label {
    border: 1px solid #c9cfdc;
    background-color: transparent;
    width: 100%;
    // height: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border-radius: 5px;
    color: #c9cfdc;
    cursor: pointer;
    font-size: 17px;
  }
}

// Mensagens de feedback
.error-message {
  color: #e74c3c;
  font-size: 12px;
  padding-left: 0px;
}

.float-right {
  float: right;
}

loading .mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary) !important;
}

// Espacamentos e separadores
.sub-title {
  margin-bottom: 15px;

  h3 {
    color: #610082;
    font-weight: 400;
    font-size: 20px;
  }
}

.section {
  padding: 0px 0px 20px 0px;
  margin-bottom: 40px;
  align-items: flex-start;

  &:last-of-type {
    border-bottom: none;
  }
}

// Configuração do calendário do moment
#calendarPesquisa .multiple-date-picker .picker-days-row .picker-selected {
  background-color: #632782;
  color: #fff;
}

#calendarPesquisa .multiple-date-picker .picker-days-row div {
  border: none;
  color: #3f3356;
  background-color: transparent;
  text-align: center;

  &:hover {
    background-color: #00bec1;
    cursor: pointer;
    color: #fff;
  }
}

#calendarPesquisa .multiple-date-picker .picker-days-row .past {
  color: #d0c9d6;

  &:hover {
    cursor: not-allowed !important;
  }
}

#calendarPesquisa .multiple-date-picker .picker-days-row .picker-empty {
  color: #ecebed;
}

#calendarPesquisa .multiple-date-picker .picker-days-week-row {
  color: #d0c9d6;
}

// SlideToggle
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #fff;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #00bec1;
}

// Sobrescrevendo propriedade nativa do angular material
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;
}

.mat-horizontal-stepper-header-container {
  max-width: 600px;
  margin: 0 auto;
}

#options-respostas-auditoria div.mat-horizontal-stepper-header-container {
  max-width: 400px;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: #600082;
}

.mat-step-header .mat-step-icon-selected {
  background-color: #600082;
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: #600082;
}

.mat-step-label-selected {
  font-weight: 400;
}

.mat-horizontal-content-container {
  overflow: initial !important;
}

// Container para o cadastro de pesquisa
.container-white {
  padding: 30px 30px 20px 30px;
  background-color: #fff;
  margin-bottom: 25px;
}

// Estilo para o passo 2 do cadastro de pesquisa (Todos os tipos de pergunta)
.question {
  // background-color: purple;

  .questionHeader {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 30px;

    .iconQuestion {
      font-size: 23px;
      color: #d6d6d6;
      margin-right: 25px;
    }

    .titleQuestion {
      span {
        font-size: 12px;
        color: #919191;
      }

      h3 {
        font-size: 16px;
        color: #3f3356;
        margin-top: 7px;
      }
    }
  }

  .questionContent {
    display: flex;
    align-items: center;

    .questionProperties {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-wrap: wrap;

      .campo-toggle {
        margin-right: 5px;
        display: flex;
        align-items: center;

        label {
          margin-left: 5px;
          color: #919191;
        }

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }

    .questionActions {
      cursor: pointer;
      color: #888888;

      span.fa-trash-o {
        position: absolute;
      }
    }
  }
}

// Configuraçoes de seçoes e perguntas do passo 2

.title-step-section {
  color: #6d0086;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 900;
}

.interviewConfig {
  display: flex;
  flex-direction: column;

  .headerConfig {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;

    span {
      color: #d6d6d6;
      margin-right: 20px;
      font-size: 20px;
    }

    h3 {
      color: #888888;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .contentConfig {
    .subSection {
      .subSectionTitle {
        h4 {
          padding-bottom: 10px;
          border-bottom: 1px solid #e6e6e6;
          font-size: 14px;
          margin-bottom: 20px;
          color: #919191;
          font-weight: 400;
        }
      }

      .subSectionContent {
        margin-bottom: 30px;

        .addAlternative {
          margin-top: 10px;
          display: flex;
          align-items: center;
          color: #00bec1;
          font-size: 14px;
          cursor: pointer;

          .btnCircle {
            background-color: #632782;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }
    }

    .actionConfig {
      display: flex;
      justify-content: flex-end;

      button {
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }

  .containerConfigInput {
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;

    &:last-of-type {
      border-bottom: 0;
    }

    .campo {
      display: flex;
      align-items: center;
      flex-grow: 1;
      margin-right: 5px;
    }

    .configInput {
      flex-grow: 1;
      margin-right: 10px;
    }

    .configAction {
      display: flex;
      align-items: baseline;
      color: #888;
      cursor: pointer;
      margin-left: 10px;
    }
  }
}

.interviewItem.selecionado {
  border: 1px solid #00bec1;
  border-radius: 5px;
}

// Sobrescrita do width default do agm-map (inherit), componente utilizado na tela de produtividade
agm-map {
  width: 100% !important;
}

// Sobrescrita de background default do angular material no componente de slider, utilizado na tela de auditoria na resposta do tipo escala numérica
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label,
.mat-primary .mat-slider-track-fill {
  background-color: #00bec1 !important;
}

// Sobrescrita de padding do angular material
.mat-horizontal-content-container {
  padding: 0;
  // Sobrescrita do label do mat-slide-toggle do angular material
}

.mat-slide-toggle-content {
  color: #919191;
  font-size: 17px;
  font-weight: 400;
}

// Esse estilo é exibido no passo 4 de cadastro de pesquisa
.inputError app-cota-percentual .cotaPercentual {
  border: 1px solid red;
}

// Nuvem de tags presentes no cadastro de grupos e cadastro de localidades
.cloudTags {
  background-color: #f8f9fd;
  min-height: 100px;
  padding: 10px;
  border-radius: 4px;

  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .tagItem {
      position: relative;
      background-color: #610082;
      color: #fff;
      border-radius: 5px;
      margin: 0 15px 5px 0;
      padding: 7px 15px;

      .tagExcluir {
        cursor: pointer;
      }

      span {
        &:first-of-type {
          font-size: 15px;
          margin-right: 10px;
        }

        &:last-of-type {
          position: absolute;
          top: -7px;
          right: 2px;
          font-size: 30px;
        }
      }
    }
  }
}

// CALENDARIO PADRÃO
.calendario-tensai {
  width: 100%;

  .dp-day-calendar-container {
    background: transparent;
  }

  .dp-calendar-nav-container {
    background-color: #610082;
    color: #fff;
    border-radius: 5px 5px 0 0;
    font-weight: 500;
    height: 40px;

    .dp-nav-header {
      left: 0;
      right: 0;
      text-align: center;
    }

    .dp-nav-btns-container {
      padding: 0 10px;
      align-items: center;
      width: 100%;
      display: flex;
      justify-content: space-between;
      right: 0;

      .dp-calendar-nav-container-left {
        border-radius: 50%;
        height: 25px;
        width: 25px;
        background: #fff;

        button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: #fff;
          border: none;
          color: #610082;
        }
      }

      .dp-calendar-nav-container-right {
        border-radius: 50%;
        height: 25px;
        width: 25px;
        background: #fff;

        button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: #fff;
          border: none;
          color: #610082;
        }
      }
    }
  }

  .dp-calendar-wrapper {
    border: 2px solid #610082;
    border-radius: 0px 0px 5px 5px;

    .dp-weekdays {
      display: flex;
      align-items: stretch;

      .dp-calendar-weekday {
        color: #610082;
        flex-grow: 1;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        width: 30px;
      }
    }

    .dp-calendar-week {
      display: flex;
      align-items: stretch;

      .dp-calendar-day {
        width: 30px;
        height: 40px;
        flex-grow: 1;
        border: 0;
        background-color: #fff;
        border: 1px solid #fff;
        margin: 0 5px 5px 5px;
        border-radius: 5px;
        font-weight: 500;
      }

      .dp-selected {
        background: #00c6c9;
        color: #fff;
      }
    }
  }
}

// Sobrescrita das cores do toast
// #toast-container .toast-success {
//   background-color: #00bec1;
// }

// #toast-container .toast-warning {
//   background-color: #d35400;
// }

// #toast-container .toast-info {
//   background-color: #600082;
// }

// #toast-container .toast-error {
//   background-color: #c0392b;
// }

// Corrige bug nas tabelas da tela de produtividade onde fica invisível o primeiro item da tabela
#mat-dialog-0 {
  overflow: inherit;
}

// Sobrescrita do angular material no seletor de status na listagem de pesquisas
.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none !important;
}

.filters .mat-form-field-infix {
  padding: 0 !important;
  border-top: 0 !important;
}

.mat-select-value {
  color: rgb(201, 207, 202);
}

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 8px !important;
}
