/* Variables */
// z-index
$highest_index: 999999;
$high_index: 99999;
$mediumHigh_index: 9999;
$medium_index: 999;
$mediumLow_index: 99;
$low_index: 9;
$lowest_index: -1;

/* colors */
$color-white: #fff;
$color-black: #000;
$color-red: #eb5757;
$color-red-bold: #b31733;
$color-red-light: #ffd2da;
$color-red-semilight: #ffebeb;
$color-agua: #00dbe6;
$color-gray: #a2a1a1;
$color-purple: #610082;
$color-light-purple: #f1e8f3;
$color-gray-seven: #777;
$color-gainsboro: #fafafa;
$color-soft-gray-light: #dedede;
$color-soft-gray: #ebebeb;
$color-gray-bold: #656565;
$color-dark-gray: #293039;
$color-dark-aqua: #007a80;
$color-light-gray: #f4f4f4;
$color-slate-gray: #495f6e;
$color-slate-light-gray: #e4e4e4;
$color-white-gray: #f4edf7;
$color-lighty-gray: #f3f3f3;
$color-lighty-white-gray: #f6f4f7;
$color-medium-gray-light: #e3e3e3;
$color-medium-gray: #465261;
$color-purple-gray: #f4edf6;
$color-dark-purple: #260033;
$color-border-gray: #c1c6c9;
$color-light-violet: #8f00bf;
$color-thesad-purple: #d5bade;
$color-dark-turquoise: #00c2cb;
$color-medium-dark-gray: #8a8b8c;
$color-antarctic-pale-blue: #c6f0f2;
$color-text-disabled: #717070;
$color-shadow-dark: #0000001a;
$color-text-gray: #CCCCCC;

$color-orange-semilight: #fff5d7;
$color-light-orange: #ffeccf;
$color-orange-ligther: #f4ddb9;
$color-bold-orange: #885c28;
$color-yellow: #cfa72c;

$color-green-light: #bcf3d3;

// Localidades colors
$color-green-sian: #00bec1;
$color-gray-to-dark: #9191ad;
$color-input-gray-light: #faf9f9;
$color-input-search-gray-light: #f9f9f9;
$color-gray-sian: #b8b4b4;
$color-gray-little: #dcdcdc;
$color-blue-light-sian: #f2fcfc;
$color-border-card: #f8f9fd;
$color-item-background: #f8f8f8;
$color-border-green-sian: #c2f5f5;
$color-children-location-background: #fdf6ff;
$color-scrollbar: #c4c4c4;
$color-border-after: #e0e0e0;
$color-table-bottom: #eee;

/* Transition */
$transition-3ms: all 0.3s ease;

// Filters para Alterar cores no SVG:
// $color-filter-purple - filter: brightness(0) saturate(100%) invert(8%) sepia(91%) saturate(5011%) hue-rotate(284deg) brightness(89%) contrast(118%);
// $color-filter-gray-disabled - filter: brightness(0) saturate(100%) invert(79%) sepia(0%) saturate(1%) hue-rotate(221deg) brightness(83%) contrast(86%);
// $color-filter-white - filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(231deg) brightness(103%) contrast(102%);
