.green-theme {
  --primary: #00642c;
  --secondary: #078c09;
  --tertiary: #dbf3e0;
  --color-icon: #078c09;
  --dark: #015426;
  --menu: #00642c;

  // path da imagem correspondente
  --dashImg: url("../../assets/images/research-tensai-green.svg");
}
